#main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  pointer-events: none;

  &::before {
    content: "";
    background-color: #2e63f7;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transform-origin: 50% 0%;
    transform: scaleY(0);
    transition: transform time(default) ease(inout);
    transition-delay: 0.5s;
  }

  .flex-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    z-index: 2;

    /** Mobile. */
    @media (max-width: 1023px) {
      justify-content: center;
    }

    .logo {
      width: 70px;
      height: 40px;

      &-icon {
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: 0% 0%;
        transform: scale(0.7);

        img {
          margin-top: 10px;
          display: none;
          width: 150px;
        }

        svg path {
          fill: transparent;
          stroke: white;
          stroke-width: 1px;
          stroke-dasharray: 500;
          stroke-dashoffset: 500;
          transition-property: stroke, stroke-dashoffset, fill;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }

      &-text {
        position: absolute;
        bottom: 0;
        right: 0;
        transform-origin: 100% 100%;
        transform: scale(0.7);

        svg path {
          fill: transparent;
          stroke: white;
          stroke-width: 1px;
          stroke-dasharray: 100;
          stroke-dashoffset: 100;
          transition-property: stroke, stroke-dashoffset, fill;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }
      }
    }

    .navigation {
      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      ul {
        display: flex;
        align-items: center;

        li {
          opacity: 0;
          transform: translateX(10px);
          transition: opacity time(default) ease(inout),
            transform time(default) ease(inout);

          &:not(:last-child) {
            margin-right: 40px;
          }

          &:not(.cta) {
            a {
              color: white;
              font-size: 16px;
              font-weight: normal;
              letter-spacing: 1px;
              transition: color time(default) ease(inout);

              &:hover {
                color: #ffac00;
              }
            }
          }
        }
      }
    }
  }

  &.sticky {
    pointer-events: initial;

    &::before {
      transform: scaleY(1);
      transition-delay: 0s;
    }

    .flex-wrapper {
      .logo {
        &-icon img {
          display: block;
          transition-delay: 0.7s, 0.3s, 0.7s;
        }

        &-text svg path {
          fill: white;
          stroke: transparent;
          stroke-dashoffset: 0;
          transition-delay: 0.85s, 0.45s, 0.85s;
        }
      }

      .navigation ul li {
        opacity: 1;
        transform: translateX(0);

        &:nth-child(1) {
          transition-delay: 0.3s;
        }

        &:nth-child(2) {
          transition-delay: 0.45s;
        }

        &:nth-child(3) {
          transition-delay: 0.6s;
        }

        &:nth-child(4) {
          transition-delay: 0.75s;
        }
      }
    }
  }

  &.single {
    .flex-wrapper {
      justify-content: center;

      .navigation {
        display: none;
      }
    }
  }
}
