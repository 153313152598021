#page-404 {
  .section-error {
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 175px);
      padding: 75px 0 0;

      @media (max-width: 767px) {
        height: calc(100vh - 137px);
      }
      
      &-title {
        color: #49308D;
        font-family: font(title);
        font-size: 32px;
        font-weight: 800;
        text-align: center;
        
        @media (max-width: 767px) {
          font-size: 24px;
        }
      }

      &-description {
        margin: 30px 0 20px;

        p {
          color: #333947;
          font-size: 16px;
          font-weight: normal;
          line-height: 1.7;
        }
      }
    }
  }
}