.button {
  background-color: #ffc666;
  color: #2e63f7;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 195px;
  height: 40px;
  border-radius: 25px;
  cursor: pointer;
  transition: color time(default) ease(inout),
    background-color time(default) ease(inout);

  &:hover {
    background-color: #5580fc;
    color: #ffc666;
  }
}
