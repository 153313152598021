/** @import: Main. */
@import "reset";
@import "vars";
@import "fonts";

/** Body. */
html {
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: font(main);
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1300px) {
    padding: 0 25px;
  }
}

/** @import: Elements. */
@import "elements/button";

/** @import: Globals */
@import "globals/header";
@import "globals/footer";

/** @import: Pages. */
@import "pages/home";
@import "pages/404";
