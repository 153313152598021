#main-footer {
  background-color: #0544fa;

  .flex-wrapper {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      margin-right: 8px;
      color: white;
    }

    /** Mobile. */
    @media (max-width: 767px) {
      padding: 40px 0;
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
    }

    .logo {
      a {
        display: inline-block;
        img {
          width: 30px;
        }
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }
}
