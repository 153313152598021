#page-home {
  .page-title {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  /** Section hero. */
  .section-hero {
    background-color: #2e63f7;
    position: relative;
    height: 640px;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 100px 0 250px;
      height: auto;
    }
    @media (max-width: 361px) {
      height: 700px;
    }

    .images {
      position: absolute;
      bottom: 60px;
      left: calc(50% - (-15px));
      z-index: 4;
      pointer-events: none;

      /** Mobile. */
      @media (max-width: 1023px) {
        transform: translateX(-50%);
        width: 260px;
        height: 180px;
        left: calc(50% - (-30px));
        bottom: 20px;
      }

      .image {
        position: absolute;
        filter: blur(5px);
        opacity: 0;
        transform: translate(40px, 40px);
        transition-property: transform, opacity, filter;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        &-circle {
          width: 550px;
        }
        &-mockup {
          position: absolute;
          bottom: -75px;
          right: 75px;
          .image {
            transition-property: transform, opacity;
            transition-duration: 3s;
            transition-timing-function: ease(inout);
            transform: translateX(35px);
            opacity: 0;
            img {
              width: 700px;
            }
          }
        }
        &-noz {
          position: absolute;
          &-one {
            bottom: 380px;
            right: -60px;
            .image {
              img {
                width: 140px;
              }
            }
          }
          &-two {
            bottom: 0;
            right: -390px;
            .image {
              img {
                width: 200px;
              }
            }
          }
          &-three {
            bottom: 105px;
            right: -530px;
            .image {
              img {
                width: 100px;
              }
            }
          }
          .image {
            opacity: 0;
            transition-duration: 3s;
            transition-timing-function: ease(inout);
            transform: translateY(40px);
          }
        }

        /** Mobile. */
        @media (max-width: 1023px) {
          img {
            max-width: 200px;
          }

          &-mockup {
            bottom: -20px;
            right: 280px;
            .image {
              img {
                max-width: 250px;
              }
            }
          }

          &-noz {
            position: absolute;
            &-one {
              bottom: 140px;
              right: 240px;
              .image {
                img {
                  max-width: 90px;
                }
              }
            }
            &-two {
              bottom: -20px;
              right: 130px;
              .image {
                img {
                  max-width: 100px;
                }
              }
            }
            &-three {
              bottom: 30px;
              right: 70px;
              .image {
                img {
                  max-width: 70px;
                }
              }
            }
          }
        }
        /** Mobile. */
        @media (min-width: 1024px) and (max-width: 1300px) {
          img {
            max-width: 400px;
          }

          &-mockup {
            bottom: -60px;
            right: 70px;
            .image {
              img {
                max-width: 550px;
              }
            }
          }

          &-noz {
            position: absolute;
            &-one {
              bottom: 320px;
              right: -75px;
              .image {
                img {
                  max-width: 120px;
                }
              }
            }
            &-two {
              bottom: -10px;
              right: -290px;
              .image {
                img {
                  max-width: 100px;
                }
              }
            }
            &-three {
              bottom: 40px;
              right: -360px;
              .image {
                img {
                  max-width: 70px;
                }
              }
            }
          }
        }

        &:nth-child(1) {
          bottom: 0;
          left: 0;
          z-index: 1;
        }

        &:nth-child(2) {
          top: 0;
          left: 60px;
          z-index: 2;

          /** Mobile. */
          @media (max-width: 1023px) {
            left: 40px;
            top: -10px;
          }
        }

        &:nth-child(3) {
          right: 0;
          bottom: 190px;
          z-index: 1;

          /** Mobile. */
          @media (max-width: 1023px) {
            bottom: 60px;
            right: -50px;
          }
        }
      }
    }

    .line-1 {
      position: absolute;
      bottom: -5px;
      left: 0;
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1023px) {
        transform-origin: 0% 100%;
        transform: scale(0.5);
      }

      svg path {
        stroke-dasharray: 1200;
        stroke-dashoffset: 1200;
        transition: stroke-dashoffset 1.5s ease(inout);

        /** Mobile. */
        @media (max-width: 1023px) {
          stroke-width: 2px;
        }
      }
    }

    .line-2 {
      position: absolute;
      top: -45px;
      right: 0;
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1023px) {
        transform-origin: 100% 0%;
        transform: scale(0.25);
      }

      svg path {
        stroke-dasharray: 2500;
        stroke-dashoffset: 2500;
        transition: stroke-dashoffset 3.5s ease(inout);

        /** Mobile. */
        @media (max-width: 1023px) {
          stroke-width: 2px;
        }
      }
    }

    .plant-1 {
      position: absolute;
      top: calc(50% - 150px);
      left: calc(60% + 260px);
      transform: translate(-50%, -50%);
      z-index: 2;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      svg path {
        stroke: white;
        stroke-dasharray: 430;
        stroke-dashoffset: 430;
        transition: stroke-dashoffset 1.5s ease(inout);
      }
    }

    .plant-2 {
      position: absolute;
      top: calc(50% - 110px);
      left: calc(60% + 230px);
      transform: translate(-50%, -50%);
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      svg path {
        fill: transparent;
        stroke: #dc3593;
        stroke-dasharray: 750;
        stroke-dashoffset: 750;
        transition: stroke-dashoffset 1.5s ease(inout), fill 1.5s ease(inout);
      }
    }

    .wrapper {
      position: absolute;
      top: calc(50% - 30px);
      left: calc(50% - 260px);
      transform: translate(-50%, -50%);
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1100px) {
        left: calc(50% - 230px);
      }
      @media (max-width: 1023px) {
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 25px;
      }

      &-logo {
        img {
          opacity: 0;
          transform: translateX(35px);
          transition-duration: 2s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
          transition-delay: 0.4s, 0s, 0.3s;
          max-width: 200px;
        }
        margin-bottom: 35px;
      }

      &-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 385px;

        /** Mobile. */
        @media (max-width: 1100px) {
          width: 400px;
        }
        @media (max-width: 1023px) {
          justify-content: center;
        }
        @media (max-width: 757px) {
          width: 100%;
        }

        &-comunication {
          @media (max-width: 757px) {
            width: 250px;
          }
          .word {
            s {
              font-weight: 300;
            }
          }
        }

        &-noz {
          margin-bottom: 30px;
          .word {
            s {
              font-weight: 900;
            }
          }
        }

        .word {
          position: relative;
          display: flex;
          height: 40px;
          margin-bottom: 10px;

          /** Mobile. */
          @media (max-width: 1100px) {
            height: 29px;
          }
          @media (max-width: 1023px) {
            height: 22px;
          }

          &:not(:last-child) {
            margin-right: 10px;
          }

          s {
            color: white;
            font-family: font(title);
            font-size: 40px;
            transition-property: transform, opacity;
            transition-duration: 2s;
            transition-timing-function: ease(inout);
            transform: translateX(35px);
            opacity: 0;

            /** Mobile. */
            @media (max-width: 1100px) {
              font-size: 32px;
            }
            @media (max-width: 1023px) {
              font-size: 25px;
            }
          }
        }
      }

      &-cta {
        opacity: 0;
        transform: translateY(10px);
        transition: opacity time(slow) ease(inout),
          transform time(slow) ease(inout);
      }
    }

    /** Animation. */
    &.appear {
      .images {
        .image {
          opacity: 1;
          transform: translate(0);
          filter: blur(0);

          &:nth-child(1) {
            transition-delay: 0.8s, 1.2s, 1s;
          }
          &:nth-child(2) {
            transition-delay: 0.3s, 0.7s, 0.5s;
          }
          &:nth-child(3) {
            transition-delay: 1.3s, 1.7s, 1.5s;
          }
        }
      }

      .plant-1 svg path {
        stroke-dashoffset: 0;
      }

      .plant-2 svg path {
        fill: #c51f8c;
        stroke-dashoffset: 0;
        transition-delay: 0.2s, 1.2s;
      }

      .line-1 svg path {
        stroke-dashoffset: 0;
      }

      .line-2 svg path {
        stroke-dashoffset: 0;
      }

      .wrapper {
        &-logo {
          img {
            opacity: 1;
            transform: translateX(0);
          }
          &--icon svg path {
            fill: white;
            stroke: transparent;
            stroke-dashoffset: 0;
            transition-delay: 0.4s, 0s, 0.3s;
          }

          &--text svg path {
            fill: white;
            stroke: transparent;
            stroke-dashoffset: 0;
            transition-delay: 0.45s, 0.15s, 0.45s;
          }
        }

        &-title .word s {
          opacity: 1;
          transform: translateX(0);

          /** @each: Delay. */
          @for $i from 0 to 50 {
            &[data-index="#{$i}"] {
              transition-delay: #{0.01s * $i + 0.06s}, #{0.01s * $i + 0.06s};
            }
          }
        }

        &-cta {
          opacity: 1;
          transform: translateY(0);
          transition-delay: 0.6s;
        }
      }
    }
  }

  /** Section about. */
  .section-about {
    padding: 130px 0 85px;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 60px 0;
    }

    > .video {
      background-color: fade-out(black, 0.3);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      opacity: 0;
      visibility: hidden;
      cursor: default !important;
      transition: opacity time(default) ease(inout),
        visibility time(default) ease(inout);

      &.active {
        opacity: 1;
        visibility: visible;
      }

      .video-content {
        iframe {
          width: 650px;
          height: 315px;

          @media (max-width: 361px) {
            width: 50px;
          }
        }
      }
    }

    .flex-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      .video {
        position: relative;
        width: 615px;
        height: 315px;
        border-radius: 15px;
        overflow: hidden;
        z-index: 2;
        text-align: center;

        /** Mobile. */
        @media (max-width: 767px) {
          width: 100%;
          height: 174px;
        }

        &::before {
          content: "";
          background-color: #2e63f7;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 5;
          transform: translateX(-100%);
          transition: transform 2s ease(inout);
        }
        
        iframe {
          width: 650px;
          height: 315px;

          @media (max-width: 361px) {
            width: 100%;
            height: 174px;
          }
        }
      }

      .content {
        position: relative;
        flex-grow: 1;
        z-index: 2;
        width: 650px;

        /** Mobile. */
        @media (max-width: 1200px) {
          margin-left: 50px;
        }
        @media (max-width: 1023px) {
          flex-grow: unset;
          width: 450px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          margin: 40px 0 0;
        }
        @media (max-width: 767px) {
          width: 100%;
          display: block;
          text-align: left;
        }

        &-title {
          display: flex;
          flex-wrap: wrap;
          width: 550px;
          justify-content: center;
          margin: 0 auto;

          /** Mobile. */
          @media (min-width: 768px) and (max-width: 1023px) {
            justify-content: center;
          }
          @media (max-width: 1023px) {
            width: auto;
          }

          .word {
            position: relative;
            display: flex;
            height: 28px;
            margin-bottom: 10px;

            /** Mobile. */
            @media (max-width: 1023px) {
              height: 22px;
            }

            &:not(:last-child) {
              margin-right: 8px;
            }

            s {
              color: #2e63f7;
              font-family: font(title);
              font-size: 26px;
              font-weight: 800;
              position: relative;
              transition-property: transform, opacity;
              transition-duration: 1.5s;
              transition-timing-function: ease(inout);
              transform: translateX(10px);
              opacity: 0;

              /** Mobile. */
              @media (max-width: 1023px) {
                font-size: 24px;
              }
            }
          }
        }

        &-description {
          margin: 15px 0 35px;
          opacity: 0;
          transition: opacity time(slow) ease(inout);
          text-align: center;

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 100%;
          }

          p {
            color: #333947;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.7;
          }
        }
      }

      /** Animation. */
      &.appear {
        .video {
          &::before {
            transform: translateX(100%);
          }

          &-image {
            opacity: 1;
            transform: scale(1);
            transition-delay: 0.5s;
          }

          &-icon {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
            transition-delay: 0.7s;
          }
        }

        .content {
          &-title .word s {
            opacity: 1;
            transform: translateX(0);

            /** @each: Delay. */
            @for $i from 0 to 50 {
              &[data-index="#{$i}"] {
                transition-delay: #{0.01s * $i + 0.3s};
              }
            }
          }

          &-description {
            opacity: 1;
            transition-delay: 0.6s;
          }

          &-cta {
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0.75s;
          }
        }
      }
    }
  }

  /** Section platform. */
  .section-platform {
    position: relative;
    padding: 190px 0;
    z-index: 1;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 60px 0;
    }
        
    @media (max-width: 361px) {
      padding: 20px 4px;
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
        @media (max-width: 361px) {
          height: 405px;
        }
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .wrapper {
      &-text {
        display: flex;
        
        @media (max-width: 361px) {
          flex-direction: column;
          align-items: center;
        }
      }

      &-number {
        display: flex;
        flex-wrap: wrap;
        margin-right: 8px;

        .word {
          position: relative;
          display: flex;
          height: 28px;
          margin-bottom: 10px;

          /** Mobile. */
          @media (max-width: 1023px) {
            height: 22px;
          }

          &:not(:last-child) {
            margin-right: 8px;
          }

          s {
            color: #ffc666;
            font-family: font(title);
            font-size: 40px;
            font-weight: 900;
            position: relative;
            transition-property: transform, opacity;
            transition-duration: 1.5s;
            transition-timing-function: ease(inout);
            transform: translateX(10px);
            opacity: 0;

            /** Mobile. */
            @media (max-width: 1023px) {
              font-size: 24px;
            }
          }
        }
      }

      &-title {
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 361px) {
          justify-content: center;
        }

        .word {
          position: relative;
          display: flex;
          height: 28px;
          margin-bottom: 10px;

          /** Mobile. */
          @media (max-width: 1023px) {
            height: 22px;
          }

          &:not(:last-child) {
            margin-right: 8px;
          }

          s {
            color: white;
            font-family: font(title);
            font-size: 40px;
            font-weight: 900;
            position: relative;
            transition-property: transform, opacity;
            transition-duration: 1.5s;
            transition-timing-function: ease(inout);
            transform: translateX(10px);
            opacity: 0;

            /** Mobile. */
            @media (max-width: 1023px) {
              font-size: 24px;
            }
          }
        }
      }

      &-partners {
        width: 800px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        align-items: center;
        justify-content: space-between;

        /** Mobile. */
        @media (max-width: 767px) {
          width: auto;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 20px;
          grid-row-gap: 40px;
        }
        
        @media (max-width: 361px) {
          width: auto;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 8px;
          grid-row-gap: 0;
          margin-top: 10px;
        }

        .partner {
          width: 140px;
          opacity: 0;
          transform: translateY(10px);
          transition: opacity time(slow) ease(inout),
            transform time(slow) ease(inout);
          margin: 0 auto;
          text-align: center;

          /** Mobile. */
          @media (max-width: 1023px) {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        
          @media (max-width: 361px) {
            width: 80px;
            img {
              width: 100%;
            }
          }
        }
      }

      /** Animation. */
      &.appear {
        .wrapper-title .word s {
          opacity: 1;
          transform: translateX(0);

          /** @each: Delay. */
          @for $i from 0 to 50 {
            &[data-index="#{$i}"] {
              transition-delay: #{0.01s * $i};
            }
          }
        }

        .wrapper-number .word s {
          opacity: 1;
          transform: translateX(0);

          /** @each: Delay. */
          @for $i from 0 to 50 {
            &[data-index="#{$i}"] {
              transition-delay: #{0.01s * $i};
            }
          }
        }

        .wrapper-partners .partner {
          opacity: 1;
          transform: translateY(0);

          /** @each: Delay. */
          @for $i from 0 to 8 {
            &:nth-child(#{$i}) {
              transition-delay: #{0.05s * $i + 0.3s};
            }
          }
        }
      }
    }
  }

  /** Section slide. */
  .section-slide {
    position: relative;
    margin-top: -135px;
    z-index: 2;

    /** Mobile. */
    @media (max-width: 1023px) {
      overflow: hidden;
      margin: 0;
      padding: 60px 0 120px;
    }

    .flex-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      padding-right: 90px;

      /** Mobile. */
      @media (max-width: 1200px) {
        padding: 0;
      }
      @media (max-width: 1023px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      .circle {
        position: relative;
        opacity: 0;
        transition: opacity time(slow) ease(inout);

        /** Mobile. */
        @media (max-width: 1023px) {
          display: none;
        }

        &-image {
          position: relative;
          transition: transform time(fast)
            cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition-delay: 0.4s;
          z-index: 1;

          &[data-index="0"] {
            transform: rotate(0);
          }

          &[data-index="1"] {
            transform: rotate(7.6deg);
          }

          &[data-index="2"] {
            transform: rotate(14.5deg);
          }

          &[data-index="3"] {
            transform: rotate(21.3deg);
          }

          &[data-index="4"] {
            transform: rotate(27.8deg);
          }

          &[data-index="5"] {
            transform: rotate(34.4deg);
          }

          &[data-index="6"] {
            transform: rotate(40.9deg);
          }

          &[data-index="7"] {
            transform: rotate(47.8deg);
          }
        }

        &-dots {
          position: absolute;
          top: calc(50% - 81px);
          right: 1px;
          width: 12px;
          height: 12px;
          z-index: 3;

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px;
            height: 8px;

            &::before {
              background-color: #2e63f7;
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border-radius: 50%;
              opacity: 0;
              transform-origin: 50% 50%;
              transform: translate(-50%, -50%) scale(0.7);
              transition: transform time(fast) ease(inout),
                opacity time(fast) ease(inout);
              z-index: 1;
            }

            svg {
              position: absolute;
              top: calc(50% - 4px);
              left: calc(50% - 4px);
              transform: translate(-50%, -50%) rotate(-90deg);

              circle {
                stroke-dasharray: 88;
                stroke-dashoffset: 88;
                transition: stroke-dashoffset 0.5s ease(inout);
                transition-delay: 0s;
              }
            }

            &.active {
              &::before {
                opacity: 1;
                transform: translate(-50%, -50%) scale(1.8);
                transition: transform time(default) ease(inout),
                  opacity time(default) ease(inout);
                transition-delay: 0.7s;
              }

              svg {
                circle {
                  stroke-dashoffset: 0;
                  transition: stroke-dashoffset 6s ease(inout);
                  transition-delay: 0.7s;
                }
              }
            }
          }
        }

        &-tabs {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 2;

          .tab-content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: opacity time(default) ease(inout),
              visibility time(default) ease(inout);
            transition-delay: 0s;

            &.active {
              opacity: 1;
              visibility: visible;
              transition-delay: 0.4s;
            }

            /** Variations [Index]. */
            &[data-index="0"] {
              .bubble {
                position: absolute;
                top: calc(50% + 50px);
                left: calc(50% + 95px);
                transform: translate(-50%, -50%);
                z-index: 0;
              }

              .iphone {
                position: absolute;
                bottom: 115px;
                right: 55px;
                z-index: 5;
                transform-origin: 60% 100%;
                transform: rotate(10deg);
                opacity: 0;
                transition: opacity time(default) ease(inout),
                  transform time(default) ease(inout);
                transition-delay: 1s;
              }

              .nuts {
                position: absolute;
                top: 230px;
                right: 60px;
                z-index: 5;
                img {
                  width: 200px;
                }
              }

              /** Animation. */
              &.active {
                .iphone,
                .macbook {
                  opacity: 1;
                  transform: rotate(0);
                  transition-delay: 0.4s;
                }
              }
            }

            &[data-index="1"] {
              .bubble {
                position: absolute;
                top: calc(50% + 50px);
                left: calc(50% + 95px);
                transform: translate(-50%, -50%);
                z-index: 0;
              }

              .line {
                position: absolute;
                bottom: 205px;
                left: 315px;
                clip-path: polygon(0 0, 0 0, 0 0, 0 0);
                transition: clip-path time(default) ease(inout);
                transition-delay: 1s;
                svg {
                  transform: rotate(-20deg);
                  width: 160px;
                }
              }

              .image {
                position: absolute;
                opacity: 0;
                transition: opacity time(default) ease(inout),
                  transform time(default) ease(inout);
                transition-delay: 1s;

                &-1 {
                  top: 215px;
                  left: 280px;
                }

                &-2 {
                  bottom: 190px;
                  right: 140px;
                  transform: translateY(-10px);
                }

                &-3 {
                  bottom: 130px;
                  right: 75px;
                  transform: translateX(10px);
                  img {
                    width: 295px;
                  }
                }
              }

              /** Animation. */
              &.active {
                .line {
                  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                  transition-delay: 0.55s;
                }

                .image {
                  opacity: 1;

                  &-1 {
                    transition-delay: 0.4s;
                  }

                  &-2 {
                    transform: translateY(0);
                    transition-delay: 0.95s;
                  }

                  &-3 {
                    transition-delay: 0.8s;
                    transform: translateX(0);
                  }
                }
              }
            }

            &[data-index="2"] {
              .bubble {
                position: absolute;
                top: calc(50% + 50px);
                left: calc(50% + 80px);
                transform: translate(-50%, -50%);
                z-index: 1;
              }

              .iphone {
                position: absolute;
                bottom: 115px;
                left: 260px;
                z-index: 3;
                opacity: 0;
                transform: translateY(10px);
                transition: opacity time(default) ease(inout),
                  transform time(default) ease(inout);
                transition-delay: 1s;
              }

              .image {
                position: absolute;
                opacity: 0;
                transition: opacity time(default) ease(inout),
                  transform time(default) ease(inout);
                transition-delay: 1s;

                &-1 {
                  bottom: 190px;
                  right: 80px;
                  z-index: 6;
                }
              }

              /** Animation. */
              &.active {
                .iphone {
                  opacity: 1;
                  transform: translateY(0);
                  transition-delay: 0.55s;
                }

                .image {
                  &-1 {
                    opacity: 1;
                    transition-delay: 0.7s;
                  }
                }
              }
            }

            &[data-index="3"] {
              .bubble {
                position: absolute;
                top: calc(50% + 50px);
                left: calc(50% + 80px);
                transform: translate(-50%, -50%);
                z-index: 1;
              }

              .image {
                position: absolute;
                opacity: 0;
                transition: opacity time(default) ease(inout),
                  transform time(default) ease(inout);
                transition-delay: 1s;

                &-1 {
                  top: 225px;
                  left: 340px;
                  z-index: 1;
                  transform: translateX(10px);
                }

                &-2 {
                  bottom: 0;
                  right: 155px;
                  z-index: 1;
                  transform-origin: 100% 100%;
                  transform: rotate(11.5deg) translate(63px, -27px);
                }

                &-3 {
                  top: 340px;
                  left: 240px;
                  z-index: 5;
                  transform: translate(90px, -50px) rotate(30deg);
                }

                &-4 {
                  bottom: 260px;
                  left: 240px;
                  z-index: 3;
                  transform: translate(160px, -50px) rotate(-30deg);
                }

                &-5 {
                  bottom: 175px;
                  left: 350px;
                  z-index: 4;
                  transform: translate(130px, -50px) rotate(30deg);
                }
              }

              /** Animation. */
              &.active {
                .line svg path {
                  stroke-dashoffset: 0;
                  transition-delay: 0.4s;
                }

                .image {
                  opacity: 1;

                  &-1 {
                    transform: translateX(0);
                    transition-delay: 0.6s;
                  }

                  &-2 {
                    transform: rotate(0) translate(0, 0);
                    transition-delay: 0.7s;
                  }

                  &-3,
                  &-4,
                  &-5 {
                    transform: translate(0, 0);
                  }

                  &-3 {
                    transition-delay: 0.9s;
                  }

                  &-4 {
                    transition-delay: 0.85s;
                  }

                  &-5 {
                    transition-delay: 0.8s;
                  }
                }
              }
            }

            &[data-index="4"] {
              .bubble {
                position: absolute;
                top: calc(50% + 50px);
                left: calc(50% + 80px);
                transform: translate(-50%, -50%);
                z-index: 1;
              }

              .image {
                position: absolute;
                opacity: 0;
                transition: opacity time(default) ease(inout),
                  transform time(default) ease(inout);
                transition-delay: 1s;

                &-1 {
                  top: 220px;
                  left: 250px;
                  z-index: 1;
                }

                &-2 {
                  top: 315px;
                  right: 80px;
                  z-index: 1;
                }

                &-3 {
                  bottom: 170px;
                  right: 50px;
                  z-index: 3;
                  transform: translateX(10px);
                }
              }

              /** Animation. */
              &.active {
                .image {
                  opacity: 1;

                  &-1 {
                    transition-delay: 0.55s;
                  }

                  &-2 {
                    transition-delay: 0.4s;
                  }

                  &-3 {
                    transform: translateX(0);
                    transition-delay: 0.7s;
                  }
                }
              }
            }

            &[data-index="5"] {
              .bubble {
                position: absolute;
                top: calc(50% + 50px);
                left: calc(50% + 95px);
                transform: translate(-50%, -50%);
                z-index: 1;
              }

              .image {
                position: absolute;
                opacity: 0;
                transition: opacity time(default) ease(inout),
                  transform time(default) ease(inout);
                transition-delay: 1s;

                &-1 {
                  bottom: 140px;
                  left: 450px;
                  z-index: 7;
                  transform: translateX(10px);
                }

                &-2 {
                  bottom: 75px;
                  left: 230px;
                  z-index: 6;
                  transform-origin: 100% 100%;
                  transform: rotate(11.5deg) translate(77px, -24px);

                  img {
                    width: 400px;
                  }
                }
              }

              /** Animation. */
              &.active {
                .line svg path {
                  stroke-dashoffset: 0;
                  transition-delay: 0.4s;
                }

                .image {
                  opacity: 1;

                  &-1 {
                    transform: translateX(0);
                    transition-delay: 0.6s;
                  }

                  &-2 {
                    transform: rotate(0) translate(0, 0);
                    transition-delay: 0.7s;
                  }
                }
              }
            }

            &[data-index="6"] {
              .bubble {
                position: absolute;
                top: calc(50% + 50px);
                left: calc(50% + 95px);
                transform: translate(-50%, -50%);
                z-index: 1;
              }

              .image {
                position: absolute;
                opacity: 0;
                transition: opacity time(default) ease(inout),
                  transform time(default) ease(inout);
                transition-delay: 1s;

                &-1 {
                  bottom: 80px;
                  left: 340px;
                  z-index: 7;
                  z-index: 2;
                  img {
                    width: 350px;
                  }
                }

                &-2 {
                  bottom: 220px;
                  left: 240px;
                  z-index: 1;
                  img {
                    width: 250px;
                  }
                }

                &-3 {
                  bottom: 230px;
                  right: 50px;
                  z-index: 5;
                  transform: translate(10px, 10px);
                }

                &-4 {
                  bottom: 325px;
                  left: 215px;
                  z-index: 4;
                  transform: translateX(-10px);
                }

                &-5 {
                  top: 185px;
                  left: 385px;
                  z-index: 3;
                  transform: translateX(-10px);
                  img {
                    width: 285px;
                  }
                }
              }

              /** Animation. */
              &.active {
                .line svg path {
                  stroke-dashoffset: 0;
                  transition-delay: 0.4s;
                }

                .image {
                  opacity: 1;

                  &-1 {
                    transition-delay: 0.55s;
                  }

                  &-2 {
                    transition-delay: 0.4s;
                  }

                  &-3 {
                    transform: translate(0);
                    transition-delay: 0.65s;
                  }

                  &-4 {
                    transform: translateX(0);
                    transition-delay: 0.8s;
                  }
                }
              }
            }

            &[data-index="7"] {
              .bubble {
                position: absolute;
                top: calc(50% + 50px);
                left: calc(50% + 80px);
                transform: translate(-50%, -50%);
                z-index: 1;
              }

              .macbook {
                position: absolute;
                bottom: 200px;
                left: 235px;
                z-index: 3;
                opacity: 0;
                transform: translateY(10px);
                transition: opacity time(default) ease(inout),
                  transform time(default) ease(inout);
                transition-delay: 1s;
              }

              /** Animation. */
              &.active {
                .macbook {
                  opacity: 1;
                  transform: translateY(0);
                  transition-delay: 0.55s;
                }
              }
            }
          }
        }
      }

      .right {
        width: 530px;
        flex-shrink: 0;
        margin: 305px 0 0 80px;

        /** Mobile. */
        @media (max-width: 1200px) {
          width: 400px;
        }
        @media (max-width: 1023px) {
          width: 530px;
          margin: 0;
          text-align: center;
        }
        @media (max-width: 767px) {
          width: 100%;
          text-align: left;
        }

        .tabs {
          position: relative;
          width: 100%;
          transition-property: height;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
          overflow: hidden;

          .tab-content {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: opacity time(default) ease(inout),
              visibility time(default) ease(inout);

            &--title {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              margin-bottom: 7px;

              /** Mobile. */
              @media (min-width: 768px) and (max-width: 1023px) {
                justify-content: center;
              }

              .word {
                position: relative;
                display: flex;
                height: 46px;
                margin-bottom: 10px;

                /** Mobile. */
                @media (max-width: 1023px) {
                  height: 30px;
                }

                &:not(:last-child) {
                  margin-right: 13px;

                  /** Mobile. */
                  @media (max-width: 1023px) {
                    margin-right: 8px;
                  }
                }

                s {
                  color: #2e63f7;
                  font-size: 40px;
                  font-family: font(title);
                  font-weight: 900;
                  position: relative;
                  transition-property: transform, opacity;
                  transition-duration: 1.5s;
                  transition-timing-function: ease(inout);
                  transform: translateX(10px);
                  opacity: 0;

                  /** Mobile. */
                  @media (max-width: 1023px) {
                    font-size: 28px;
                  }
                }
              }
            }

            &--description {
              width: 370px;
              opacity: 0;
              transition: opacity time(default) ease(inout);

              /** Mobile. */
              @media (max-width: 1023px) {
                width: 100%;
              }

              p {
                color: #333947;
                font-size: 16px;
                font-weight: normal;
                line-height: 1.7;
              }
            }

            /** Variations [Index]. */
            &[data-index="0"] {
              .tab-content--flex {
                display: flex;
                align-items: flex-start;
                margin-top: 35px;

                /** Mobile. */
                @media (min-width: 768px) and (max-width: 1023px) {
                  justify-content: center;
                }

                .separator {
                  position: relative;
                  bottom: -12px;
                  opacity: 0;
                  transform: translateX(-10px);
                  transition: opacity time(default) ease(inout),
                    transform time(default) ease(inout);

                  &:nth-child(2) {
                    margin-right: 20px;
                  }

                  &:nth-child(4) {
                    margin-right: 16px;
                  }
                }

                .item {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  &-icon {
                    height: 40px;
                    margin-bottom: 15px;
                    opacity: 0;
                    transform: translateY(10px);
                    transition: opacity time(default) ease(inout),
                      transform time(default) ease(inout);
                  }

                  &-title {
                    color: #7d86a1;
                    font-size: 14px;
                    font-weight: normal;
                    text-align: center;
                    opacity: 0;
                    transform: translateY(10px);
                    transition: opacity time(default) ease(inout),
                      transform time(default) ease(inout);

                    /** Mobile. */
                    @media (max-width: 1023px) {
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            &[data-index="6"] {
              .tab-content--flex {
                display: flex;
                margin-top: 20px;

                /** Mobile. */
                @media (min-width: 768px) and (max-width: 1023px) {
                  justify-content: center;
                }

                .item {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  &:not(:last-child) {
                    margin-right: 40px;

                    /** Mobile. */
                    @media (max-width: 1023px) {
                      margin-right: 10px;
                    }
                  }

                  &-icon {
                    height: 35px;
                    opacity: 0;
                    transform: translateY(10px);
                    transition: opacity time(default) ease(inout),
                      transform time(default) ease(inout);
                  }

                  &-title {
                    color: #7d86a1;
                    font-size: 14px;
                    font-weight: normal;
                    margin-top: 8px;
                    opacity: 0;
                    transform: translateY(10px);
                    transition: opacity time(default) ease(inout),
                      transform time(default) ease(inout);
                  }
                }
              }
            }

            /** Animations. */
            &.active {
              opacity: 1;
              visibility: visible;

              .tab-content--title .word s {
                opacity: 1;
                transform: translateX(0);

                /** @each: Delay. */
                @for $i from 0 to 40 {
                  &[data-index="#{$i}"] {
                    transition-delay: #{0.01s * $i + 0.2s};
                  }
                }
              }

              .tab-content--description {
                opacity: 1;
                transition-delay: 0.6s;
              }

              &[data-index="0"] .tab-content--flex {
                .separator {
                  opacity: 1;
                  transform: translateX(0px);

                  &:nth-child(2) {
                    transition-delay: 0.8s;
                  }

                  &:nth-child(4) {
                    transition-delay: 1s;
                  }
                }

                .item {
                  &-icon {
                    opacity: 1;
                    transform: translateY(0);
                  }

                  &-title {
                    opacity: 1;
                    transform: translateY(0);
                  }

                  &:nth-child(1) {
                    .item-icon {
                      transition-delay: 0.7s;
                    }

                    .item-title {
                      transition-delay: 0.75s;
                    }
                  }

                  &:nth-child(3) {
                    .item-icon {
                      transition-delay: 0.9s;
                    }

                    .item-title {
                      transition-delay: 0.95s;
                    }
                  }

                  &:nth-child(5) {
                    .item-icon {
                      transition-delay: 1.1s;
                    }

                    .item-title {
                      transition-delay: 1.15s;
                    }
                  }
                }
              }

              &[data-index="6"] .tab-content--flex {
                .item {
                  &-icon {
                    opacity: 1;
                    transform: translateY(0);
                  }

                  &-title {
                    opacity: 1;
                    transform: translateY(0);
                  }

                  &:nth-child(1) {
                    .item-icon {
                      transition-delay: 0.7s;
                    }

                    .item-title {
                      transition-delay: 0.75s;
                    }
                  }

                  &:nth-child(2) {
                    .item-icon {
                      transition-delay: 0.8s;
                    }

                    .item-title {
                      transition-delay: 0.85s;
                    }
                  }

                  &:nth-child(3) {
                    .item-icon {
                      transition-delay: 0.9s;
                    }

                    .item-title {
                      transition-delay: 0.95s;
                    }
                  }

                  &:nth-child(4) {
                    .item-icon {
                      transition-delay: 1s;
                    }

                    .item-title {
                      transition-delay: 1.05s;
                    }
                  }

                  &:nth-child(5) {
                    .item-icon {
                      transition-delay: 1.1s;
                    }

                    .item-title {
                      transition-delay: 1.15s;
                    }
                  }
                }
              }
            }
          }
        }

        .navigation {
          display: flex;
          align-items: center;
          margin-top: 42px;

          /** Mobile. */
          @media (min-width: 768px) and (max-width: 1023px) {
            justify-content: center;
          }

          button {
            position: relative;

            &::before {
              content: "";
              background-color: #e6e6e6;
              position: absolute;
              width: 30px;
              height: 30px;
              border-radius: 50%;
              top: calc(50% - 1px);
              left: 50%;
              transform: translate(-50%, -50%);
              opacity: 0;
              transition: opacity time(default) ease(inout);
              z-index: 1;
            }

            &:nth-child(3) {
              &::before {
                left: calc(50% - 1px);
              }
            }

            img {
              position: relative;
              z-index: 2;
            }

            &:hover {
              &::before {
                opacity: 1;
              }
            }
          }

          .count {
            display: flex;
            margin: 0 25px;

            span {
              color: #7d86a1;
              font-size: 16px;
              font-weight: normal;
              width: 11px;
              text-align: center;
              display: inline-block;
            }
          }
        }
      }

      /** Appear. */
      &.appear {
        .circle {
          opacity: 1;
        }
      }
    }
  }

  /** Section management. */
  .section-management {
    padding: 30px 0 160px;

    /** Mobile. */
    @media (max-width: 1023px) {
      overflow: hidden;
      margin: -120px 0 0;
      padding: 0 0 60px;
    }

    .container { 
      @media (max-width: 361px) {
        margin-top: 55px;
      }
    }

    .flex-wrapper {
      display: flex;
      align-items: center;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-direction: column;
        justify-content: center;
      }

      .content {
        position: relative;
        width: 400px;
        flex-shrink: 0;
        margin-right: 210px;
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          text-align: center;
          margin: -25px 0 0;
          order: 2;
        }
        @media (max-width: 767px) {
          width: 100%;
          text-align: left;
        }

        &-title {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          /** Mobile. */
          @media (min-width: 768px) and (max-width: 1023px) {
            justify-content: center;
          }

          .word {
            position: relative;
            display: flex;
            height: 28px;
            margin-bottom: 10px;

            /** Mobile. */
            @media (max-width: 1023px) {
              height: 22px;
            }

            &:not(:last-child) {
              margin-right: 8px;
            }

            s {
              color: #2e63f7;
              font-family: font(title);
              font-size: 32px;
              font-weight: 800;
              position: relative;
              transition-property: transform, opacity;
              transition-duration: 1.5s;
              transition-timing-function: ease(inout);
              transform: translateX(10px);
              opacity: 0;

              /** Mobile. */
              @media (max-width: 1023px) {
                font-size: 28px;
              }
            }
          }
        }

        &-description {
          width: 380px;
          margin: 15px 0 35px;
          opacity: 0;
          transition: opacity time(default) ease(inout);

          /** Mobile. */
          @media (max-width: 767px) {
            width: 100%;
          }

          p {
            color: #333947;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.7;
          }
        }

        &-cta {
          opacity: 0;
          transform: translateY(10px);
          transition: opacity time(default) ease(inout),
            transform time(default) ease(inout);

          /** Mobile. */
          @media (min-width: 768px) and (max-width: 1023px) {
            display: flex;
            justify-content: center;
          }
        }
      }

      .right {
        position: relative;

        /** Mobile. */
        @media (max-width: 1023px) {
          transform-origin: 50% 50%;
          left: 280px;
          transform: scale(0.5);
        }

        &-image-1 {
          position: absolute;
          left: -200px;
          z-index: 2;
          opacity: 0;
          transform: translate(220px, -50%);
          transition: opacity time(default) ease(inout),
            transform time(default) ease(inout);
          img {
            width: 850px;
          }
        }
      }

      /** Animate. */
      &.appear {
        .content {
          &-title .word s {
            opacity: 1;
            transform: translateX(0);

            /** @each: Delay. */
            @for $i from 0 to 50 {
              &[data-index="#{$i}"] {
                transition-delay: #{0.01s * $i};
              }
            }
          }

          &-description {
            opacity: 1;
            transition-delay: 0.3s;
          }

          &-cta {
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0.45s;
          }
        }

        .right {
          &-image-1 {
            opacity: 1;
            transform: translate(0, -50%);
            transition-delay: 0.7s;
          }
        }
      }
    }
  }

  /** Section contact. */
  .section-contact {
    position: relative;
    background-color: #2e63f7;
    padding: 80px 0 0;
    overflow: hidden;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 60px 0 0;
    }

    .line-1 {
      position: absolute;
      bottom: -5px;
      left: -200px;
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      svg {
        transform: scaleX(-1);

        path {
          stroke-dasharray: 700;
          stroke-dashoffset: 700;
          transition: stroke-dashoffset 1.5s ease(inout);
        }
      }
    }

    .line-2 {
      position: absolute;
      bottom: -60px;
      left: calc(50% - 450px);
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      svg path {
        stroke-dasharray: 700;
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 1.5s ease(inout);
      }
    }

    .line-3 {
      position: absolute;
      bottom: -5px;
      right: -5px;
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      svg path {
        stroke-dasharray: 1300;
        stroke-dashoffset: 1300;
        transition: stroke-dashoffset 1.5s ease(inout);
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .flex-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-wrap: wrap;
      }

      .content {
        width: 405px;
        margin-bottom: 50px;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
          padding: 0;
        }

        &-title {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          margin-bottom: 15px;

          /** Mobile. */
          @media (max-width: 1023px) {
            justify-content: center;
          }

          .word {
            position: relative;
            display: flex;
            height: 45px;
            margin-bottom: 10px;

            /** Mobile. */
            @media (max-width: 1023px) {
              height: 28px;
            }

            &:not(:last-child) {
              margin-right: 8px;
            }

            s {
              color: white;
              font-family: font(title);
              font-size: 40px;
              font-weight: 900;
              position: relative;
              transition-property: transform, opacity;
              transition-duration: 1.5s;
              transition-timing-function: ease(inout);
              transform: translateX(10px);
              opacity: 0;

              /** Mobile. */
              @media (max-width: 1023px) {
                font-size: 28px;
              }
            }
          }
        }

        &-description {
          opacity: 0;
          transition: opacity time(default) ease(inout);

          /** Mobile. */
          @media (max-width: 1023px) {
            text-align: center;
          }

          p {
            color: white;
            font-weight: normal;
            line-height: 1.7;
          }
        }
      }

      .form {
        background-color: white;
        width: 900px;
        padding: 45px 30px;
        border-radius: 25px 25px 0 0;
        opacity: 0;
        transition: opacity time(default) ease(inout);

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
        }

        .form-row {
          &:not(:last-child) {
            margin-bottom: 18px;
          }

          &.form-flex {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 25px;

            /** Mobile. */
            @media (max-width: 1023px) {
              grid-template-columns: repeat(1, 1fr);
              grid-row-gap: 18px;
            }
          }
          p {
            text-align: right;
          }

          .field-group {
            position: relative;
            width: 100%;

            .label {
              color: #7d86a1;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 5px;
              padding-left: 5px;
            }

            .field {
              background-color: #f4f6fb;
              color: #7d86a1;
              font-size: 16px;
              font-weight: normal;
              width: 100%;
              height: 48px;
              border: none;
              border-radius: 5px;
              padding: 10px;
            }

            textarea {
              resize: none;
              height: 95px !important;
            }
          }
        }

        &-submit {
          display: flex;
          justify-content: flex-end;
          margin-top: 30px;

          /** Mobile. */
          @media (max-width: 1023px) {
            justify-content: center;
          }

          button {
            background-image: linear-gradient(
              to right,
              #2e63f7,
              #87a5fd,
              #2e63f7
            );
            background-size: 200% 100%;
            background-position: left center;
            color: white;
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 195px;
            height: 40px;
            border-radius: 25px;
            cursor: pointer;
            transition: background-position time(default) ease(inout);

            &:hover {
              background-position: right center;
            }
          }

          .is-loading {
            border: 4px solid white;
            border-top: 4px solid #ffac00;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            animation: spin 2s linear infinite;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
      }
    }

    /** Animation. */
    &.appear {
      .line-1,
      .line-2 {
        svg path {
          stroke-dashoffset: 0;
        }
      }

      .line-3 {
        svg path {
          stroke-dashoffset: 0;
        }
      }

      .flex-wrapper {
        .content {
          &-title .word s {
            opacity: 1;
            transform: translateX(0);

            /** @each: Delay. */
            @for $i from 0 to 50 {
              &[data-index="#{$i}"] {
                transition-delay: #{0.01s * $i};
              }
            }
          }

          &-description {
            opacity: 1;
            transition-delay: 0.3s;
          }
        }

        .form {
          opacity: 1;
          transition-delay: 0.45s;
        }
      }
    }
  }
}
